// Import the functions you need from the SDKs you need
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
let firebaseApp;
setUp()

function setUp() {
    const firebaseConfig = {
        apiKey: "AIzaSyDyixb3SNi7S0II7OwhYvBDHGpa8yfDDOg",
        authDomain: "bankmanagement-3cecd.firebaseapp.com",
        projectId: "bankmanagement-3cecd",
        storageBucket: "bankmanagement-3cecd.appspot.com",
        messagingSenderId: "1082362344971",
        appId: "1:1082362344971:web:cfa4ccc22f81fb5511b90e",
        measurementId: "G-774QV574F9"
    };
    firebaseApp = firebase.initializeApp(firebaseConfig);
}
export default firebaseApp;