import React, { createContext, useState } from 'react'

export const context = createContext()

export default function UserContext(props) {
    const [allUser, setAllUser] = useState([])
    const [activeUser, setActiveUser] = useState(0)
    const [inActiveUser, setInActiveUser] = useState(0)
    const [todayAUser, setTodayAUser] = useState(0)
    const [todayIUser, setTodayIUser] = useState(0)

    return (
        <context.Provider value={{ allUser, setAllUser, activeUser, setActiveUser, inActiveUser, setInActiveUser, todayAUser, todayIUser, setTodayAUser, setTodayIUser }}>
            {props.children}
        </context.Provider>
    )
}
