import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AdminSidebar from '../Components/AdminSidebar'
import Loader from '../Components/Loader'
import { isMobile } from 'react-device-detect'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import axios from 'axios'

export default function Statement() {
    const { id } = useParams()
    const [showLoader, setShowLoader] = useState(true)
    const [data, setData] = useState([])

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        axios.post(process.env.REACT_APP_API_URL + '/get-all-statements', { userid: id })
            .then((res) => {
                if (res.status == 200) {
                    setShowLoader(false)
                    setData(res.data)
                }
            })
    }

    const column = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <p>{moment(value).format('DD-MM-YYYY')}</p>
                    )
                }
            }
        },
        {
            name: "amount",
            label: "amount",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "statment",
            label: "statment",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "type",
            label: "type",
            options: {
                filter: false,
                sort: false,
            }
        },
    ]

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        rowsPerPage: 100,
        pagination: true,
        responsive: true
    };

    return (
        <>
            {showLoader ? <Loader /> : ""}
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-12">
                            <MUIDataTable
                                title={"Statement Details"}
                                data={data}
                                columns={column}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
