import React, { useEffect, useState } from 'react'
import AdminSidebar from '../Components/AdminSidebar'
import { isMobile } from 'react-device-detect'
import axios from 'axios'
import MUIDataTable from 'mui-datatables'
import moment from 'moment'
import { GrTransaction } from 'react-icons/gr'
import { Link } from 'react-router-dom'
import { BiSolidPackage } from "react-icons/bi";


export default function AdminAnalytics() {
    const [userData, setUserData] = useState([])

    useEffect(() => {
        getUserData()
    }, [])

    const getUserData = () => {
        axios.get(process.env.REACT_APP_API_URL + '/get-all-user')
            .then((res) => {
                if (res.status == 200) {
                    setUserData(res.data)
                }
            })
    }

    const column = [
        {
            name: "date",
            label: "Date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (value) => {
                    return (
                        <p>{moment(value).format('DD-MM-YYYY')}</p>
                    )
                }
            }
        },
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "walletAddress",
            label: "Wallet Address",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "balance",
            label: "Balance",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "Deposit",
            label: "Deposit",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "rank",
            label: "rank",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "rankRewardRecived",
            label: "rankRewardRecived",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "totalTeamDeposit",
            label: "totalTeamDeposit",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "allTimeDeposit",
            label: "allTimeDeposit",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "totalEarn",
            label: "totalEarn",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "affiliateEarn",
            label: "affiliateEarn",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "totalWithdraw",
            label: "totalWithdraw",
            options: {
                filter: false,
                sort: false,
            }
        },
        {
            name: "verified",
            label: "Verified",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <p>{value == true ? "true" : "false"}</p>
                    )
                }
            }
        },
        {
            name: "_id",
            label: "Statements",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Link to={"/statement/" + value} className="btn btn-primary"><GrTransaction /></Link>
                    )
                }
            }
        },
        {
            name: "_id",
            label: "Packages",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value) => {
                    return (
                        <Link to={"/package/" + value} className="btn btn-secondary"><BiSolidPackage /></Link>
                    )
                }
            }
        },
    ]

    const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        rowsPerPage: 100,
        pagination: true,
        responsive: true
    };

    return (
        <>
            <AdminSidebar />
            <div id="page-container" className={isMobile ? "MobileScreen" : "BigScreen"}>
                <div className="container mt-4">
                    <div className="row">
                        <div className="col-12">
                            <MUIDataTable
                                title={"user details"}
                                data={userData}
                                columns={column}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
