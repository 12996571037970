import React from 'react'
import './App.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Login from './Pages/Login';
import ProtectedRouter from './Pages/ProtectedRouter';
import AdminAnalytics from './Pages/AdminAnalytics';
import UserContext from './Context/UserContext';
import Contact from './Pages/Contact';
import Statement from './Pages/Statement';
import Package from './Pages/Package';

export default function App() {
  return (
    <>
      <UserContext>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Login />} />
            <Route path='/admin-analytics' element={<ProtectedRouter><AdminAnalytics /></ProtectedRouter>} />
            <Route path='/contact' element={<ProtectedRouter><Contact /></ProtectedRouter>} />
            <Route path='/statement/:id' element={<ProtectedRouter><Statement /></ProtectedRouter>} />
            <Route path='/package/:id' element={<ProtectedRouter><Package /></ProtectedRouter>} />
          </Routes>
        </BrowserRouter>
      </UserContext>
      <ToastContainer />
    </>
  )
}
