import React, { useContext } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';



export default function Login() {



    const [userRole, setuserRole] = useState(1)
    const [passwordType, setPasswordType] = useState("password");
    const navigate = useNavigate();
    const [alluser, setAlluser] = useState([])

    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')


    const submitloginform = () => {
        if (email == process.env.REACT_APP_LOGIN && pass == process.env.REACT_APP_PASS) {
            localStorage.setItem('login', true)
            navigate('/contact')
        } else {
            toast.error("Invalid Credential!")
        }
    }

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text")
        } else {
            setPasswordType("password")

        }
    }







    return (



        <>
            <div className="login-root">
                <div className="box-root flex-flex flex-direction--column" style={{ minHeight: '100vh', flexGrow: 1 }}>
                    <div className="loginbackground box-background--white padding-top--64">
                        <div className="loginbackground-gridContainer">
                            <div className="box-root flex-flex" style={{ gridArea: 'top / start / 8 / end' }}>
                                <div className="box-root" style={{ backgroundImage: 'linear-gradient(white 0%, rgb(247, 250, 252) 33%)', flexGrow: 1 }}>
                                </div>
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '4 / 2 / auto / 5' }}>
                                <div className="box-root box-divider--light-all-2 animationLeftRight tans3s" style={{ flexGrow: 1 }} />
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '6 / start / auto / 2' }}>
                                <div className="box-root box-background--blue800 animationLeftRight tans3s" style={{ flexGrow: 1 }} />
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '7 / start / auto / 4' }}>
                                <div className="box-root box-background--blue animationLeftRight" style={{ flexGrow: 1 }} />
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '8 / 4 / auto / 6' }}>
                                <div className="box-root box-background--gray100 animationLeftRight tans3s" style={{ flexGrow: 1 }} />
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '2 / 15 / auto / end' }}>
                                <div className="box-root box-background--cyan200 animationRightLeft tans4s" style={{ flexGrow: 1 }} />
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '3 / 14 / auto / end' }}>
                                <div className="box-root box-background--blue animationRightLeft" style={{ flexGrow: 1 }} />
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '4 / 17 / auto / 20' }}>
                                <div className="box-root box-background--gray100 animationRightLeft tans4s" style={{ flexGrow: 1 }} />
                            </div>
                            <div className="box-root flex-flex" style={{ gridArea: '5 / 14 / auto / 17' }}>
                                <div className="box-root box-divider--light-all-2 animationRightLeft tans3s" style={{ flexGrow: 1 }} />
                            </div>
                        </div>
                    </div>
                    <div className="box-root padding-top--24 flex-flex flex-direction--column" style={{ flexGrow: 1, zIndex: 9 }}>
                        <div className="box-root padding-top--48 padding-bottom--24 flex-flex flex-justifyContent--center">
                            <h1 className='studentslogin'>Login</h1>
                            {/* <h1 className=' mt-5 text-center studentslogin'>
                                {value.Lan === 'english' ? EnglishWords.CompanyLogin : GujaratiWords.CompanyLogin} </h1> */}
                        </div>
                        <div className="box-root flex-flex flex-justifyContent--center ">
                        </div>
                        <div className="formbg-outer">
                            <div className="formbg">
                                <div className="formbg-inner padding-horizontal--48">
                                    <form id="stripe-login" >
                                        <div className="field padding-bottom--24">
                                            <label htmlFor="email">Email</label>
                                            {/* <label htmlFor="email">   {language === 'english' ? EnglishWords.Email : GujaratiWords.Email} </label> */}
                                            <input type="text" id='email' name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="" />


                                        </div>
                                        <div className="field padding-bottom--24">
                                            <label htmlFor="pass">Password</label>
                                            <input type={passwordType} id='pass' name="password" value={pass} onChange={(e) => setPass(e.target.value)} placeholder="" />
                                            <button className="btn-btn-primary eyepass" type='button' onClick={togglePassword}>
                                                {passwordType === "password" ? <FaEyeSlash /> : <FaEye />}
                                            </button>
                                        </div>


                                        <div className="field padding-bottom--24">
                                            <input href="#" type="submit" defaultValue="Login" onClick={submitloginform} />
                                        </div>


                                        {/* <div className="col-md-6   mt-2">
                                                    <select onChange={(e) => onLanguageChange(e.target.value)} class="form-select secondclass" aria-label="Default select example">
                                                        <option selected={language == 'english' ? true : false} value="english">  english</option>
                                                        <option selected={language == 'gujarati' ? true : false} value="gujarati">  Guajrati </option>
                                                    </select>
                                                </div> */}

                                    </form>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
