import React from 'react'
import './Loader.css'

export default function Loader() {
    return (
        <>
            <div className='loader-background'>
                <div className="loader-walk">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </>
    )
}
