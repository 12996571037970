import React from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedRouter(props) {
    return (
        <>
            {localStorage.getItem('login') ? props.children : <Navigate to={'/'} />}
        </>
    )
}
